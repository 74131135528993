.content{
 background-color: white;
 max-width: 90vw;
 vertical-align: top;
 position: relative;
 overflow: auto;
}
#content{
	padding-top: 1em;
	overflow-x: scroll;
}
.content h1{
	font-size: 15pt;
	/* margin-top: 0px; */
}

.content h2{
	font-size: 12pt;
}

.retired{
background-color:#ff4e4e;
color: white;
padding: 1em;
}

.content img{
	margin: .5em;
	border: solid #fff 3px;
	border-radius: 5px;
	height: auto;
	max-width: 500px;
}

.content table{
	border: none;
}

.content table img{
	max-width: 200px;
}

.piczone{
    position: absolute;
    right: -210px;
    top: 0px;
    width: 205px;
}
.piczone img{
	padding: 0px;
	margin: 0px;
}

.content table tr:last-child td{
    border-bottom: none;
}

.content td{
	padding: 5px;
	border-bottom: solid 1px #d4dbdc;
	border-right: solid 1px #d4dbdc;
}

.content table td:last-child{
    border-right: none;
}

td.content > div, li, td {
	font-size: small;
	vertical-align: top;
}

.fl-block {
	display: flex;
	align-items: flex-end;
}
.fl-block > * {
	flex: 1 1;
	padding-inline-start:0;
}

.fl-block img {
	max-width: 200px;
}
.fl-block li {
	text-align: center;
	list-style: none;
}


/* ==================================================== > 650 =========== */
@media (min-width: 650px){
.content{
 padding:0px 20px 20px 20px; 
 margin-top: 0px;
  max-width: 750px;
 min-width: 680px;
}
}

.content ul{
	list-style: none;
    padding-inline-start: 8px;

}

.content H1 {
	padding-top: 0.1em;
	font-size: xx-large;
}

.content H2 {
	font-size: 16pt;
	padding-top: 2em;
}



/* ==================================================== < 650 =========== */
@media (max-width: 650px){
.content img{
	max-width: 300px;
	height: auto;
	transition: 0.3s;
}
}


/* ==================================================== < 450 =========== */
@media (max-width: 450px){
.content img, .content a > img{
	max-width: 90vw;	
	transition: 0.3s;
}
}

.button{
display: block;
margin-top: .5em;
background-color: brown;
padding: 1em;
border-radius: 5px;
text-align: center;
}

td.pictures{
	width: 200px;
	min-width: 200px;
	vertical-align: top;
}
td.pictures img {
min-height: 100px;
}

.notfound{
	font-size: 15em;
	text-align: center;
}

