.brands{
	display: flex;
	flex-direction: row;
	align-content: center;
	flex-wrap:wrap; 
	justify-content: center;
	width: 100%;
	padding: 10px;
}

.brands > a > img {
	border-color: #fdfd;
	border-radius: 0px;
	height: 1.7em;
	margin-top: .1em;
}

ul.brands > li{
	flex:0 1 ;
	flex-direction: row;
	list-style: none;
	padding: 0px 1em;
    display: table;
    height: 40px;	
}

.brand img{
	margin-bottom: 2em;
	max-height: 150px;
	max-width: 400px;
	margin-right: auto;
}

div.LogoBG {
	padding: 2em;
    padding-bottom: 0px;
    background-color: white;
    text-align: center;
}

.brands a {
    flex: 1 1 140px;
    align-content: center;
    display: table-cell;
    text-align: center;
    border-radius: 3px;
    vertical-align: middle;
}

div.brands > a {
    flex: 1 1 140px;
    align-content: center;
    display: table-cell;
    text-align: center;
    border-radius: 3px;
	vertical-align: middle;
	background-color: #fff;
	margin: .1em;
	height: 43px;
    padding: 8px 30px;
}
.brand{
	list-style: none;
	display: flex;
	flex-direction: row;
	align-content: center;
	width: 100%;
	padding: 10px;	
}
.brand > li:first-child{
	flex: 1 1 auto;
	margin-left: 2em;
}
.brand > li:last-child{
	max-width: 300px;
	min-width: 200px;
	margin-left: 2em;
}

/* ==================================================== < 450 =========== */
@media (max-width: 450px){
	.brand{
		flex-direction: column;
		padding-left: 0px;
	}
	.brand > li:last-child{
		width: 98vw;
		margin-left: 0em;
	}
	.brand > li:first-child{
		margin-left: 0em;
	}	
	}