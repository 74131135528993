*, :after, :before {
    box-sizing: border-box;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root{
    background: #fff0 no-repeat top;
    background-image: url(/images/bg_02.jpg);
    background-position-x: calc(50% - 11.2rem);
    background-position-y: 3px;
    transition: .3s ease;
    height: 100vh;
}

@media (max-width: 650px){

#root{
    background-position-x: calc(50% + -25.6em);
    /* background-size: 2543px; 
     background-position-y: -104px;
    */
}

}

section {
   /* display: flex; 
    flex-direction: column; */
    height: calc(100vh - 260px);
    width: 100%;
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); 
    */
    margin-left: auto;
    margin-right: auto;
    margin-top: 138px;
    max-width:1200px;

}

.pages{
    /*
     flex: 1 0 auto; 
    */
	width: 100%;
    padding-bottom: 0.5rem;
     padding-left: 1em;
    padding-right: 1em; 
}

.pt50 {
    padding-top: 50px;
}

.pages H1 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

.pages H2, H3, b, strong, th {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: large;
}


.itmttl{
    display: table-cell;
    top: 48px;
    vertical-align: bottom;
    text-transform: uppercase;
    padding-left: 0.2em;
    text-shadow: 0px 2px 7px white;
}
.mtcrrct{
    margin-top: inherit;
}

/* ==================================================== < 650 =========== */
@media (min-width: 650px){
    .pages H1 {
        padding-top: 0.1em;
        font-size: xx-large;
    }

    .pages H2 {
        font-size: 16pt;
    }

    .infoblock h2, h3{
        padding-top: 0px;
    }
}

/* =================================================== Mobile =========== */
@media (max-width: 650px){
    section {
        margin-top: 112px;
    }
    .pages{
        margin-top: -131px;
    }
    .itmttl{
        height: 6em;
    }
    .mtcrrct{
        margin-top: 0px;
    }
}




table{
    width: 100%;
}

table.main{
    height: 100%;
}



ul.pages > li{
    list-style: none;
}

.index{
    margin-top: -169px;    
}

.white{
    margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}

.topic{
    display: block;
    margin: 0px;
    padding: 5px;
    padding-bottom: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    color: red;
    font-weight: 500;
}

.cleaner{
    clear: both;
}





.toptels {
    padding: .5em;
    color: #154784;
    position: absolute;
    left: calc(50% + -1.6em);
    top: 1.7em;
    font-size: 14pt;
    font-style: italic;
    font-weight: 750;
    display: flex;
    width: 650px;
}
.toptels a {
    color: #5581a5;
}
.toptels > div:first-child {
    text-align: right;
    font-size: .6em;
    width: 380px;
    padding-top: 1.5em;
    flex: 1 0 auto;
}




._2h_fa {
    margin: 0px auto;
}

._3KHIQ {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    width: 4em;
    font-size: inherit;
}

._1XybS {
    width: 1em;
    height: 1em;
    font-size: 7px;
    /*border-radius: 50%;*/
    background: currentColor;
    flex:0 0 Auto;
    -webkit-animation: 1.2s cubic-bezier(.47,0,.75,.72) infinite;
    animation: 1.2s cubic-bezier(.47,0,.75,.72) infinite dots;
}

._3FRxt {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s;
}

._8XiJu {
    padding: 3px 0;
}

._1o7ne {
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s;
}

@keyframes dots {
  from {
    background: rgb(255, 255, 255);

  }

  to {
    background: rgb(0, 40, 70);
  }
}



