.news{
    padding: 1em 0em;
}

.news a{
    padding: 0em 0.7em 0.1em;
    margin: 0em 0.4em 0em;
    border-radius: 1em;
    border: 1px dashed #7e9192;
    text-align: center;
    line-height: 1rem;
    text-decoration: none;
    transition: .5s;
    background-color: #dcedff;
}

.news a:hover{
    background-color: white;
    transition: .5s;
}

.flLeft{
    float: left;
    padding-right: 2em;
    padding-bottom: 2em;
}