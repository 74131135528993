.yaotziv{
    width:100%;
    height:560px;
    overflow:hidden;
    position:relative;
}


.badge {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 300px;
    max-width: 1200px;
    height: 100vh;
    padding: 20px 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
}


.badge__comments{
    display: flex;
    background-color: white;
    height: 100%;
    align-items: flex-start;
    flex-direction: row;
    padding: 0px 0px 20px 20px;
    border-radius: 9px;
    height: 100%;
}
.comment__stars {
    margin-bottom: 8px;
}

.mini-badge__stars {
    width: 88px;
}

.stars-list {
    display: flex;
    justify-content: space-between;
    width: 126px;
    /* padding-inline-start: 0px; */
}
.stars-list__star {
    width: 16px;
    height: 16px;
    background-image: url(https://yastatic.net/s3/front-maps-static/maps-front-org-badge-api/fd03c36523a776f599bc6a48d0e89bf0d70eae65/out/assets/_/178df0f18f6025c2e764130c7d1ac463.svg);
    background-position-x: 50%;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    list-style: none;
}

.stars-list__star._half {
    background-image: url(https://yastatic.net/s3/front-maps-static/maps-front-org-badge-api/fd03c36523a776f599bc6a48d0e89bf0d70eae65/out/assets/_/a53d90b8e3365aa721fcf6939fa3f81a.svg);
}

.stars-list__star._empty{
    background-image: url(https://yastatic.net/s3/front-maps-static/maps-front-org-badge-api/de7ca847033dfe6f3480aa08810ad5e8d68c0202/out/assets/_/ee15bab6241eec6618f0f5720fa6daa9.svg);
}

.mini-badge__rating-info {
    display: flex;
    align-items: center;
    height: 48px;
}
.mini-badge__stars-count{
    font-size: 48px;
    margin-right: 12px;
    line-height: 56px;
}

.mini-badge {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.mini-badge {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
}


.comment {
    flex: auto;
    border-bottom: 1px solid #f6f6f6;
    padding: 24px 0;
    width: 18%;
    height: 10em;
}



.comment__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 16px;
    height: 40px;
}

.comment__photo {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 12px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
}

.comment__photo._icon_5 {
    background: #f285aa;
}

.comment__photo._icon_7 {
    background: #5798d9;
}

.comment__name-n-date{
    height: 10px;
}
.comment__name {
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
}

.comment__date {
    color: #999;
    line-height: 16px;
    margin: 0;
}

.comment__text {
    max-height: 182px;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    padding-right: 4em;
}


.badge__form{
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 6px;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    font-weight: 500;
}

.badge__form-text {
margin: 6px;
    font-size: 16px;
    white-space: nowrap;
}

.badge__link-to-map:hover {
    background: #1a6df2;
}

.badge__link-to-map {
    background: #258ffb;
    color: #fff;
}
.badge__link-to-map {
    margin: 6px;
    padding: 12px 16px;
    line-height: 16px;
    border-radius: 8px;
    -webkit-transition: background-color .1s ease-out;
    transition: background-color .1s ease-out;
}

