.comments{
    
}
.comments > li{
    background-color: #f1f3f5;
    margin-top: .5em;
    border-radius: .5em;
    padding: 1em;
    overflow-x: auto;
}

.comments span{
    font-size: 11pt;
    font-weight: 500;
    color: black;
}
.comments a{
    padding: 0em 0.7em 0.2em;
    margin: 0em 0.4em 0.2em;
    border-radius: 1em;
    border: 1px dashed #a7b8b9;
    text-align: center;
    line-height: 1rem;
    text-decoration: none;
    transition: .5s;
}

.comments a:hover{
    background-color: white;
    transition: .5s;
}
