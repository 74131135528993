.stepnav{
    width: 100%;
    padding: 0.5em 0 0.8em 0.4em;	
    margin-top: 2.4em;
    margin-left: -5px;

    display: flex; 
    align-items: center;
    }
    

ul.stepnav{
    list-style: none;
    list-style: none;
    padding-inline-start: 0px;
}
    
    .stepnav li{
    float: left;
    margin: 0 .5em 0 1em;
    }
    
   .stepnav a{
    text-transform: uppercase;
    font-size: 9pt;
    background-color: #e0e8f0;
    padding: 0.3em 1em 0.3em 1em;
    float: left;
    text-decoration: none;
    color: #1b5a7d;
    z-index: 1;
    text-shadow: 0 1px 1px rgba(255,255,255,.5);
    border-radius: .4em;
    border: 1px dotted;
    margin-left: -0.7em;
    transition: .3s ease;
    }
    
    .stepnav a:hover{
    background: #fff;
    }
    
    /*
        .stepnav b::after{
            content: "";
            background-color: aliceblue;
            height: 1.9em;
            margin-top: -1.25em;
            position: absolute;
            right: -1em;
            top: 63%;
            width: 1.9em;
            z-index: -1;
            transform: rotate(45deg);
            border-top: 1px solid #5b87a0;
            border-right: 1px solid #5b87a0;
            border-radius: 0.4em;
        }
    */
    .stepnav a:hover::after{
    background: #fff;
    }
    
    .stepnav .current,
    .stepnav .current:hover{
    font-weight: bold;
    background-color: #e9ffd4;
    }
    
    .stepnav .current::after,
    .stepnav .current::before{
    content: normal;
    }

    i.icon {
        display: inline-block;
        opacity: 1;
        margin: 0 .25rem 0 0;
        width: 1.18em;
        height: 1em;
        font-family: Icons;
        font-style: normal;
        font-weight: 400;
        text-decoration: inherit;
        text-align: center;
        speak: none;
        font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    
    i.icon.chevron.right:before {
        content: "\f054";
    }
    
    i.icon:before {
        background: 0 0!important;
    }


/* ==================================================== > 650 =========== */
@media (min-width: 650px){

}

/* ==================================================== < 650 ===========*/ 
@media (max-width: 650px){
    .stepnav a{
        margin-left: 0px;
        border: 1px dotted;
    }
    .stepnav li{
        max-width: 350px;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .stepnav span{
        text-transform: lowercase;
        overflow: hidden;
    }

}

/* ==================================================== < 420 ===========*/
@media (max-width: 420px){

}