.tools-ai-enhance__control-btn-wr:last-child {
    margin-bottom: 0;
}

.tools-ai-enhance__control-btn-wr {
    margin-bottom: 10px;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tools-ai-enhance__control {
    min-width: 240px;
}

.tools-ai-enhance__control {
    padding: 15px;
    border-radius: 4px;
    background: #ffffffb3;
    display: inline-block;
    min-width: 258px;
    margin-bottom: 25px;
}

@media (max-width: 1799px)
.sk-text {
    font-size: 16px;
}


.sk-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
}

.tools-ai-enhance__control-btn.active {
    background: #0062fe;
}

.tools-ai-enhance__control-btn {
    display: inline-block;
    width: 34px;
    height: 17px;
    position: relative;
    margin-left: 15px;
    border-radius: 9px;
    background: #a4afb9;
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
}

.tools-ai-enhance__control-btn.active span {
    left: -webkit-calc(100% - 15px);
    /* left: calc(100% - 15px); */
}

.tools-ai-enhance__control-btn span {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    -webkit-transition: left .3s;
    -o-transition: left .3s;
    /* transition: left .3s; */
    left: 2px;
    top: 2px;
}

.pat{
    position: relative;
}

div.pat::after {
 content: "";
 background: url(/images/pat2.png);
 opacity: 0.4;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
 position: absolute;
 z-index: -1; 
}

