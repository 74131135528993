a {
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;  
  color: #1b5a7d;
  text-decoration: none;
  line-height: 1rem;
}

a:hover {
  text-decoration: underline;

}

.topic > a {
  font-weight: 600;
  color: #1b5a7d;
}

hr{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.App-logo {
  height: 40vmin;
}

.NavPenel{
    background-color: #fff;
    border-bottom: solid 3px #5f8eaf;
    height: 3.2em;
    z-index: 9;
}

.trl{
  transform: translateX(-338px);
  transition: .3s ease;
}

.trr{
  transform: translateX(0px);
  transition: .3s ease;
}
.TelOnMenu a{
  color: #324d97;
  font-weight: 600;
  font-size: 1.3em;
  background-color: #d5ffaa;
  display: inline;
}
/* ==================================================== > 650 =========== */
@media (min-width: 650px){
.App-header {
    text-align: center;
    justify-content: space-evenly;
    width: 650px;
 }

}

.App-header {
    display: flex;
    align-items: center;
    padding-inline-start: 80px;    
    font-weight: 600;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    height: 3em;
}

.App-header a.active{
  display: block;
  background-color: #f0f8ff;
  padding: 13px 9px;
  border-bottom: 3px solid #324d97;
  font-weight: 700;
}

.App-header a{
  display: block;
  padding: 13px 9px;
  border-bottom: 3px solid #5f8eaf;
}
.App-header li {
    flex: 0 0 Auto;
    list-style: none;
}

.App-link {
  color: #0967d3;
}

/* ==================================================== < 650 ===========*/ 
@media (max-width: 650px){
.App-header > ul {
    justify-content: flex-end;
    padding-right: 1em;
   /*
    width: calc(100vw - 4em);
    font-size: calc(7px + 0.9vmin);
    font-size: 7pt;
    */
}
.NavPenel{
  height: 3rem;
}
}
/* ==================================================== < 420 ===========*/
@media (max-width: 420px){
.App-header {
    font-size: 10pt;
}
.App-header li {
    flex: 0 0 2em;
    list-style: none;
}
.NavPenel{
    height: 3rem;
}
}



input.filter{
  margin: 0 Auto 0.5em;
  padding: 10px;
  padding-left: 20px;
  background-color: #d4dbdc;
  background-image: none;
  border: 1px solid #fff;
  width: 90%;
  display: flex;
}

input.orderform, textarea.orderform{
  margin: 0 0 .5em 0;
  padding: 10px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #fff;
  border-radius: .3em; 
  width: 100%;
}
textarea.orderform{
  height: 8em;
}
input[type='text']
{
    margin: .5em;
    padding: 22px;
    font-size: medium;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 5em;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
}


input:invalid {
  border: 1px dashed #ff9b9b;
  outline: none;
}

input:valid {
  border: 2px solid white;
  outline: none;
}
input:focus:invalid {
  outline: none;
}
/* Убираем обводку у форм */
:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}

.sticky{
  position: sticky;
  top: 0px;
 }

 .nobg{
  background: none !important;
 }

 .cntr{
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
 }