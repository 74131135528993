ul.tag{
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-content: center;
    padding-inline-start: 0px;
}
.tag li{
    flex: 1 1 auto;
    margin: .5em;
    padding: 0 1em;
    border-radius: 1em;
    border: 1px dashed;
    text-align: center;
    background-color: aliceblue;
}

.tag li:hover{
    background-color: #fff;
}

.tag li > a{
    display: block;
    line-height: 2em;
    text-decoration: none;
    
}