.gallery {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-top: 0;
    padding-inline-start: 0px;
}
ul.gallery > li{
	list-style: none;
    flex: 1 1 30%;
    text-align: center;
    border: solid 1px #eaeaea;
    background-color: white;
    /* height: 230px; */
    margin: 4px;
    padding: 10px;
    border-radius: 5px;

}
.gallery a{
display: table;
width: 100%;
text-align: center;
}

.gallery img{
    border-radius: 5px;
}

.gallery a.flex{
    flex: 1 0 auto;
}
