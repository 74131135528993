:root {
    --cyan: #17a2b8;
    --border: #fff;
}
*, :after, :before {
    box-sizing: border-box;
}
.tags{
    margin-bottom: 1em;
    display: flex;
    flex-wrap: wrap;
}

.tags span{
     /* border: solid 2px #ffffff; */
     padding: 1em;
     background-color: #ff8989;
     color: white;
     margin-right: 5px;
     border-radius: 7px;
     border-style: solid;
     border-width: 2px;
     border-color: azure;
}

.config {
    background-color: #fff;
    padding: 2em;
    top: 18%;
    left: 10vw;
    z-index: 6;
    margin-top: -10px;
    background-image: url(/images/pat2.png);
    background-repeat: repeat;
    background-position: top;
    background-attachment: scroll;
    font-size: smaller;

}
.config table {
    margin-bottom: 2em;
    width: 100%;
    background-color: white;
}

.config th {
    background-color: #f1f8ff;
}

.config td {
    padding-left: 1em;
    border-width: 0px 1px 2px 0px;
    border-color: var(--border);
    border-style: dashed;

}
.config td.prc {
    font-size: initial;
    color: darkgreen;
    cursor: pointer;
}
.config thead {
    height: 5em;
}
.config td > img {
    min-width: 200px;
}
.config td:first-child {
    vertical-align: top;
}

.delflt {
    display: inline-block;
    cursor: pointer;
    padding-left: 0.5rem;
    font-weight: 600;
}


.config input {
    margin: .5em 0;
    border: none;
    border-radius: 0.3em;
    padding: 1.4em;
    margin-left: 1em;
    width: 80%;
}

.config input[type='checkbox'] {
    border: none;
    height: auto;
    margin: 3px;
}

.config select {
    font-size: smaller;
    max-width: 200px;
    height: auto;
    margin: 3px;
    border: 1px solid #eaeaea;
    border-radius: 0.4em;
    padding: 5px;
    margin-left: 1.5em;
    background-color: #f6faff;
}
.changed-sel {
    background-color: #618baf !important;
    color: white;
}

.config label {
font-size: xx-small;
}
.config textarea {
    width: 100%;
    border-radius: .2em;
    margin: .5em 0;
    border: none;
    padding: 1em;
    height: 50vh;
    border: solid 1px #ddd;
}
.config h2 {
    color: var(--cyan);
    margin: 0 0 .5em;
    text-shadow: 0 0 6px #fcfcfc;
}
