.nMenu{
  list-style: none;
  padding-inline-start: 0em;
  margin-top: 0;
  
}
.mobile{
  background-color: #ffffff70;
  border-radius: 3px;
  /* padding: 0px 10px; 
  */
}

ul.nMenu:not(.mobile){
  width: 290px;
}

.mobile li:hover{
  background-color: white; 
 
}

.nMsticky{
  position: sticky;
  top:50px;
  }

.scroll{
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: 2px;

}
::-webkit-scrollbar{
    width:7px;
}
::-webkit-scrollbar-thumb{
  border-width:1px 1px 1px 2px;
  border-color: #777;
  background-color: rgb(255, 108, 108);
}


.nMenu li {
  padding: .3em 0em;
  border-bottom: solid 1px #d8d8d8;
}

.nMenu li:last-child {
  border:none;
  
}

.nMenu li.selected {
border:none;
font-weight: 500;
}

.nMenu li.selected > a {
    color:#006E92;
    text-decoration: none;
}


ul.nMenu li:not(.selected):hover{
  background-color: #ffffff20;
}

/*

.nMenu li:not(.selected) > a {

}




/* ==================================================== > 650 =========== */
@media (min-width: 650px){
.nMenu li.selected {
    background-color: var(--menusel);
    margin: 0;
    border-radius: 0px;
    padding-left: 1em;
}

.nMenu li:not(.selected) > a {
  display: block;
}

.nMenu li:not(.selected) {

  padding-left: 1em;
}

}
/*---------------------------------------------------*/
.nMenu a {
  padding: 0.8em 0.3em;
  display: block;
  font-size: 11pt;
  font-weight: 500;
}

.nMenu > li.selected > ul.nMenu {
  background-color: #ffffffeb;
  padding: 0 1em ;
  border-radius: 5px;
}

.nMenu > li.selected > ul.nMenu li {
  padding: .3em .5em;
}

.nMenu > li.selected > ul.nMenu li > a {
  color:#3c4148;
  font-weight: 500;
}


.nMenu.selected{
  padding: 1em;
}

.nMenu span> span{
  font-size: small;
}



