.populardeps{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 10px 0px;
    margin-top: 0px;

}

.populardeps li{
    flex: 1 1 140px;
    list-style-type: none;
    align-content: center;
    margin: 0.35em;
    padding: 0.7em;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 4px #ddecff;
}

.populardeps li > a{
     font-size: 90%;
     line-height: 0.9rem;
}

.populardeps img{
    height: 100px;
}

h2.pop{
    color: red;
    display: block;
    font-size: 1.1em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.populardeps li > a > div{
    display: table-cell;
    vertical-align: middle;
    width: 100%;

}


/* =========================== МОБАЙЛ ==================================== */
@media (max-width: 420px){
    .populardeps{
        flex-direction: column;
    }
    .populardeps img{
        height: 50px;
        padding-right: .5em;
        float: left;
    }
    .populardeps li{
        display: table-cell;
        flex: 1 1 Auto;
        text-align: left;
        margin-top: 0px;
        height: 5em;
    }
    .populardeps li > a{
        display: table;
        font-size: 100%;
        line-height: 1rem;
        font-weight: 600;
        width: 100%;
    }
    .populardeps li > a > div{
        border-left: 1px solid #d7d7d7;
        padding-left: 1em;
    }
}
/*---------------------------------------------------*/





