.novinki{
    position: relative;
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 0px #d0d0d0;
    line-break: anywhere;
}

.novinki > div {
    /* display: flex; 
    */
}

.novinki > * > a {
margin-right: 20px;
float: left;
}

.novinki > * > a > img {
    border-radius: 5px;
    }
    


.novinki > a {
display: block;
padding-bottom: 1em;
font-weight: 600;
text-decoration: underline #CCC;
}
