ul.tabs{
    display: flex;
    padding: 0px;
    border-bottom: solid 2px #c6d0dc;
    z-index: 8;
    position: relative;
    justify-content: space-between;
    height: 3em;
    margin-top: 1em;
}
ul.tabs li{
    text-align: center;
    flex: 0 0 133px;
    padding:1em;
    border: solid 2px #fff;
    border-radius: 0.4em;
    border-bottom: none;
    cursor: pointer;
}

ul.tabs li.active > a{
    color:black;
    background-color: #f6f7fb;
}

ul.tabs li.active {
    background-image: url(/images/pat2.png);
    background-color: #fff;
    border: solid 2px #c6d0dc;
    border-radius: 0.4em;
    border-bottom: solid 2px #f0f0f0;
    margin-bottom: -3px;
    font-weight: 600;

}

ul.tabs li.active:after {
    width: 100%;
    background-color: white;
}




.tbslft{
    display: flex;
    align-items: flex-end;
    height: 2.8em;
}
.tbsrght{
    display: flex;
    align-items: center;
    height: 2em;
}

.dminPrice {
	position: relative;
    top: -19px;
    right: 2px;
	color: #ff6969;
	font-family: 'Oswald';
}


.minPrice {
	position: relative;
    right: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
	color: #ff6969;
	font-family: 'Oswald';
}


.from{
	padding-right: .5em;
	padding-bottom: 2px;
	font-weight: 500;
}
.rub{
	font-size: xx-large;
    font-weight: 500;
    
}
.kop{
font-size: medium;
font-weight: 500;
}

.loading{
    height: 40px;
    width: 30px;
}