table.price{
    background-color: white;

}

table.price td{
    border-bottom: 1px solid #D6D6D6;
    border-style: none none solid none;
    padding: 5px;
    font-size: 9pt;
    font-weight: 400;
    vertical-align: middle;
}

table.price th{
    font-size: 9pt;
    padding: 5px;
}

table.price tr{
    vertical-align: middle;
}

tr.inbasket{
    background-color:#f4fff4;
}


table.price tr > td:last-child{
    /* font-weight: 600;*/
    margin: 5px;
    vertical-align: middle;
}

table.price tr:last-child > td{
    border-bottom: none;
}
table.price td > p{
    margin-block-start: .7em;
    margin-block-end: .1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

table.price td > span{
    margin-block-start: .7em;
    margin-block-end: .1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 14pt;
}

table.price td:first-child :not(p){
    color: var(--red);
}

.price input{
    margin: 0em;
    padding: 15px;
    background-color: #f5f2e7;
    background-image: none;
    border: 1px solid #fff;
    /* border-radius: .1em; */
    width: 100%;
}
