#search {
    width: 230px;
    padding: 1.1em;
    height: 39px;
    color: #053053;
    position: absolute;
    transition: .3s ease;
    opacity: 1;
    left: calc(50% + 31em);
    top: 0px;
    z-index: 9;
    background-color: #e4f1ff;
    /* border: 1px solid #627185; */
    
}

#search:focus {
    opacity: 1;
    outline: none;
    width: 350px;
}

/* =================================================== Mobile =========== */
@media (max-width: 420px){

    #search {
        height: 3.1em;
        width: 350px;
        left: calc(50% + -13.6em);
        box-shadow: 0 0 0 1px rgb(122 151 181), inset 1px 2px 5px rgb(0 100 150 / 30%), 0 2px 0 hsl(0deg 0% 100% / 60%);
        border: 1px white;
        background-color: white;
        top: 7.3em;
    }

}

