
.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;  
}
.container div:last-child{
border: none;
margin-bottom: 0px;
}

/* ==================================================== > 650 =========== */
@media (min-width: 650px){
.container {
    flex-direction: row;
 }
}

.container > td {
    flex: 1 1 150px;
}

.dprts > td:first-child{
  flex: 0 1 300px;
}

.container > div {
    flex: 1 1;
}

.topics > a {
    flex: 1 1;
}

.container > a {
    margin-right: 15px;
}


.menu {
    border-right: solid;
    border-color: #eee;
    border-width: 0.4em;
    background-color: var(--menubg);
    border-radius: .5em;
    width: 100px;
    transform: 0.3s;
}
.menu li.header{
    background-color: #c2cde478;
    margin: 0;
    border-radius: .5em .5em 0px 0px;
    height: 3.4em;
    text-align: center;
    font-weight: 600;
}
.menu > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-inline-start: 0px;
    margin: 0px;
}

.menu > ul > li {
     list-style: none;
    /* padding: 5px; */
  
    padding: 5px;
    border-radius: 0em;

}

.menu a{
    padding: 0.8em 0.3em;
    display: block;
}
.menu.dprts {
    background-color: #ffffff3d;
    padding-left: 2em;
    padding-bottom: 2em;
    padding-right: 2em;
}
td.menu{
    vertical-align: top;
}

/* ==================================================== > 650 =========== */
@media (min-width: 650px){
.menu > ul > li {
    flex: 1 1 500px;
    list-style: none;
    background: none;
    border-bottom: 1px solid #ffffff59;
}
.menu:not(.dprts) {
    max-width: 296px;
}
td.menu{
    /*
    min-width: 296px;
    */
}
}

/* ==================================================== < 650 =========== */
@media (max-width: 650px){

}


li.selected{
    background-color: var(--menusel);
    padding: .5em;
    border-radius: 5px;
    
}
