:root {
    /* --menubg: #d4dbdc; */
    --menubg: #d8dfe7;
    --menusel: #f8f8ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
   
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

.f10 {
    FONT-SIZE: 10pt; 
}

.f11 {
    FONT-SIZE: 11pt; 
}

.f12 {
    FONT-SIZE: 12pt; 
}

.f13px {
    FONT-SIZE: 13px; 
}
.f13 {
    FONT-SIZE: 13pt; 
}
.f14  {
    FONT-SIZE: 14pt;
}
.f16 {
    FONT-SIZE: 16pt;
}

.f30 {
    FONT-SIZE: 30pt;
}

.b {
    font-weight: 600;
}
.i{
    font-style: italic;
}
.tac{
    text-align: center;
}
.tae{
    text-align: end;
}
.tar{
    text-align: right;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.p5{
    padding: .5em;
}
.rds{
    border-radius: .5em;
}
.mh{
    margin: 0em 1em;
}
body {
  margin: 0;
  height: 100%;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f3f5;

  color: #37607d !important;
  overflow: scroll;
  font-size: small;

text-transform: none;
    line-height: 1.28581;
    letter-spacing: 0.4px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,Icons16,sans-serif;
    /* font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.red{
    color: var(--danger);
}

.center{
    text-align: center;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}