img.logo{
  width: 350px;
  position: absolute;
  top: 1.2rem;
  left: calc(50% - 37.4rem);
  opacity: 1;
  transition: .3s ease;
    /* animation: 1.5s cubic-bezier(0.29, 0, 0.29, 0.95); 
    z-index: 10; */
}
.z6{
  z-index: 6;
}

.z10{
  z-index: 10;
}

@keyframes logo {
  from {
  top: 6.6em;
  left: 18em;
  opacity: 0;
  }

  to {
  top: 4.6em;
  left: 22em;
  opacity: 1;
  }
}


@media (max-width: 650px){
    img.logo{
      /*
        width: 309px;
        position: absolute;
        top: 5.5em;
        left: calc(50% - 19em);
        transition: .3s ease;
      */
        width: 12.6rem;
        position: absolute;
        top: -1.8rem;
        left: 0px;
        margin-left: .5em;
        transition: .3s ease;
        z-index: 11;
     
    }
}