
.lbox{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1em;
    border-radius: .5em;
    position: fixed;
    top: 30%;
    left: 50%;
    height: auto;
    width: auto;
    transform: translate(-50%, -30%);
    min-width: 250px;
    min-height: 150px;
    max-width: 70%;
    max-height: 94vh;
    z-index: 12;
    box-shadow: -4px -3px 19px #7d7d7d;
    background-image: url(/images/pat2.png);
    background-repeat: repeat;
    background-position: top;
    background-attachment: scroll;
    transition: width .5s, height .5s;
}

.lbox img{
    max-height: 80vh;
}


.prev{
    position: absolute;
    left: 0px;
    top: 2em;
    /* height: 78%; */
    bottom: 2em;
    width: 40%;
    background: url(/images/lightbox-btn-prev.gif) left 15% no-repeat;
    cursor: pointer;
    opacity: .3;
    transition: .5s;
}
.next{
    position: absolute;
    right: 0px;
    top: 2em;
    /* height: 78%; */
    bottom: 2em;
    width: 40%;
    background: url(/images/lightbox-btn-next.gif) right 15% no-repeat;
    cursor: pointer;
    opacity: .3;
    transition: .5s;
}

.prev:hover, .next:hover{
    opacity: 1;
    transition: .5s;
}

@media (max-width: 420px){

    .lbox{
        padding: .1em;
        border-radius: .1em;
        min-width: 150px;
        min-height: 150px;
        max-width: 97%;
        width: max-content;

    }

}

.lboverlay{
    position: fixed;
    background-color: black;
    width: 100vw;
    height: 100vh;
    animation: 0.5s cubic-bezier(0.29, 0, 0.29, 0.95) lboverl;
    opacity: .5;
    z-index: 10;
    top: 0;
    left: 0;
  }

  @keyframes lboverl {
    from {
    opacity: 0;
    }
    to {
    opacity: .5;
    }
  }

  .wbg{
      background-color: white;
      margin-top: 3px;
  }
  .loader{
    background-image: url(/images/lightbox-ico-loading.gif);
    background-repeat: no-repeat;
    background-position: center;
    transition: width .5s, height .5s;
  }
  .hide{
      opacity: 0;
      transition: .5s;
  }
  .show{
    opacity: 1;
    transition: .5s;
    image-rendering: optimizeSpeed;
    object-fit:scale-down;
  }