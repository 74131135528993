.banner{
    position: fixed;
    right: 1em;
    top: 5em; 
    max-width: 50em;
    background-color: white;
    padding: 1em;
    border-radius: .5em;
    box-shadow: 2px 3px 12px 0px #c3c3c3;
}

.banner > ul{
    list-style: none;
    padding-inline-start: 0px;
}

.banner img{
    max-width: 100%;
}

.hidn{
    display: none;
}

/* =================================================== Mobile =========== */
@media (max-width: 420px){

    .banner {
        left: 2vw;
        top: 12.6em;
        width: 95vw;
        max-width: none;
    }
    
}