table.sendOrder {
    width: 100%;
}
table.sendOrder td, table.sendOrder th{
    border-bottom: 1px solid #aaa;
}
table.sendOrder th{
    text-align: left;
}
.itogo{
    text-align: right;
    color: red;
}