.footer{
    /*
    flex: 0 0 auto;
    */
  
    width: 100%;
    /* height: 12.5rem; 
    */
    margin-top: 4em;
    text-align: center;
   /* background-position-y: calc(50% - 30vh);
     background-image: url(https://presscustomizr.com/wp-content/uploads/2019/05/ZjESfxPI3R.jpg); 
   */
  }

.phones{
    font-size: x-large;
    color: #f60;   
    margin-block-start: .1em;
    margin-block-end: .5em;
}

.phones a{
    color: #f60;   
}

input[type='tel'], button.tel, input[type="tel"]::placeholder
{
  /*
  font-family: "Kode Mono", monospace;
  */
  font-family: "Victor Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 1.5em;
  color: white;
  font-optical-sizing: auto;
}

input[type='tel'], button.tel
{
  margin: .5em;
  padding: .75em;
  border: 4px solid #fff;
  border-radius: 5em;
  background-color: #A0B8CD;
  transition: .3s;
}


input[type='tel']
{
  background-image: url('/svg/phone_input.svg');
  background-repeat: no-repeat;
  background-position-x: 91%;
  background-position-y: center;
  background-size: 30px;
  width: 14.6em;

  box-shadow: inset 0 1px 16px 8px rgba(0,0,0,.075);
}
input[type="tel"]::placeholder
{
  font-size: 1em;
}

button.tel
{
  cursor: pointer;
  padding: 0.8em 1.5em;
  box-shadow: inset 0 1px 16px 8px rgba(0,0,0,.075);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  
}

input[type='tel']:hover, button.tel:hover
{
  background-color: #4fab5a;
  transition: .3s;
}