.dprts{
    padding-left: 1em;
}

.dprts h1{
    margin-block-start: 0em;
    margin-block-end: 0.83em;
}
.dprtdsc img{
    max-width: 550px;
}

/* ==================================================== < 450 =========== */
@media (max-width: 550px){
    .dprtdsc img{
        max-width: 96vw;
    }
}