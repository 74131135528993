.Slider{
position: relative;
/* width: 1200px; */
left:5px;   
border-radius: 5px;
}
.SlPlaceholder{
    border-radius: .3em;
    width: 1200px;
    height: 150px;
    left:-5px;
    padding-top: 60px;
    background-color: rgb(255, 255, 255);
}
.Slider IMG{
margin: 0px;
padding: 0px;
}



.SliderNav{
    bottom: 0;
    text-align: center;
    list-style-type: none;
    padding-inline-start: 0px;
}

.dot{
    margin: 0 .4em;
    display: inline-block;
    width: .8em;
    height: .8em;
    opacity: 1;
    cursor: pointer;
    background-color: white;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0 rgb(0 0 0 / 22%);
}
.dot.active{
    background-color: var(--info);;
    border: 2px solid var(--info);
}

.Slider IMG.hidden{
    opacity: 0;
}
.Slider IMG.show{
    animation-duration: .4s;
    animation-name: show;
}
.Slider IMG.hide{
    animation-duration: .4s;
    animation-name: hide;
}


  @keyframes show {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }

  @keyframes hide {
    from {
        opacity: 1;
    }
  
    to {
        opacity: 0;
    }
  }