:root{
    --bskt: #f1f3f5;
    --order: #0e8daa;
    --back: #7aa6d2;
    --minusPlus: #e3ecee;
    --qstBg: #618baf;
}

.btn{
    display: inline-block;
    margin-top:.5em;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #0000;
    border: 1px solid #0000;
    padding: .375rem .75rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.qst{
    color: #ffffff;
    background-color:var(--qstBg);
    border-color: #f8f9fa;
    font-size: 1rem;
}

.bskt{
    background-color:var(--bskt);
    border-color: #f8f9fa;
    margin-top: 0em;
    font-size: .8rem;
}
.nav{
      /* background-color: var(--bskt); */
      border-color: #8296a9;
      margin: 0.2em;
      font-size: .8rem;
      flex: 1 0 auto;
}

.back{
    position: fixed;
    bottom: 2em;
    right: 0em;
    background-color:var(--back);
    border: 2px solid #f8f9fa;
    margin-top: 0em;
    font-size: .8rem;
    color:white;
    z-index: 999;
}


.bskt:hover{
    background-color: #cdfdc6;
    border-color: #97fb9f;
}

.order{
    background-color:var(--order);
    border-color: #f8f9fa;
    margin-top: 0em;
    font-size: 1rem;
    color: #f0f8ff;
    font-weight: 600;
}
.order:hover{
    background-color: #00a4b1;
    border-color: #1eadbd;
}


.plusMinus{
    background-color:var(--minusPlus);
    border-color: #f8f9fa;
    margin-top: 0em;
    width: 10%;
    font-size: 2rem;
}

.plusMinus:hover{
    background-color: #90cbd8;
    border-color: #fff;

}
.container *.plusMinus{
    flex: 1 0 Auto;
}
.bsk{
    flex-direction: row;
}
.bsk p{
    margin-block-start: 0em;
    margin-block-end: -1em;
    margin-inline-start: 8px;
    margin-inline-end: 8px;
}


.basket div.container > p{
margin: 0em 1em;
}

.basketinfo{
    background-color: #f00;
    color: #fff;
    font-size: x-small;
    padding: 0px 4px 0px 3.5px;
    margin-left: 2px;
    border-radius: 50%;
}
.submit{
    color: #ffffff;
    background-color:var(--cyan);
    font-size: 1rem;
}

.w{
    width: 100%;
}

.dialog{
    background-color: #fff;
    padding: 2em;
    border-radius: .5em;
    position: fixed;
    top: 18%;
    left: 25%;
    width: 50%;
    z-index: 11;
    /* transform: translate(-50%, -50%);*/
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    box-shadow: -4px -3px 19px #7d7d7d;

    background-image: url(/images/pat2.png);
    background-repeat: repeat;
    background-position: top;
    background-attachment: scroll;

}
.basket{
    background-image: none;
    left: 33%;
    width: 30%;
}
.dialog textarea{
    max-width: 100%;
    border-radius: .5em;
    margin: .5em 0;
    border: none;
    padding: 1em;

}
.dialog h2{
    color:var(--cyan);
    margin: 0 0 0.5em 0;
    text-shadow: 0 0 6px #fcfcfc;
}

.dialog input[type='email'], input[type='name'] {
margin: .5em 0;
border: none;
border-radius: .5em;
padding: 1em;
}

.finalmsg{
    background-color: #fff;
    padding: 1em;
    text-align: center;
    color: #ff6347;
    font-weight: 400;
}
.close{
    position: absolute;
    right: 0px;
    top: -7px;
    padding: .5em 1em;
    height: 30px;
    width: 30px;
    /* background-color: #ffc8c8;
    */
    background-image: url(/svg/close.svg);
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
}

.del{
    padding: .5em 1em;
    background-color: #ececec;
    /* opacity: .5; */
    transition: .5s;
    color: black;
}
.del:hover{
    opacity: 1;
    color: white;
}
.mob{
    position: absolute;
    top: -9px;
    right: 0px;

}
.rel{
    position: relative;
}

/* ==================================================== < 650 =========== */
@media (min-width: 650px){
.basket{
        left: 35vw;
        width: 30vw;
    }

}

/* ==================================================== < 650 =========== */
@media (max-width: 1100px){
    .basket{
        left: 33vw;
        width: 30vw;
    }
    .dialog{
        left: 14vw;
        width: 70vw;
    }

}

/* ==================================================== < 650 =========== */
@media (max-width: 420px){
.basket{
    left: 1vw;
    width: 98vw;
}
.dialog{
    left: 1vw; 
    width: 98vw;
}
}