.eqSort{
  padding-inline-start: 0em;
  padding-left: .5em;
  padding-top: 4em;
}

.eqSort li {
  /*
  list-style: none;
  margin-top: .5em;
  */
  padding-bottom: .5em;
    font-size: small;

}

.eqSort ul > li > a {
  color: #547698;
}


.eqSort span {
  font-weight: 500;
  font-size: small;

}

.eqSort ul > li::first-letter {
  text-transform: capitalize;
}


.eqSort h2 {
	color: #006E92;
	font-weight: 600;
}

/* =========================== МОБАЙЛ ==================================== */
@media (max-width: 420px){
  .eqSort{
    padding-top: 0em;
  }
}