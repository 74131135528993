.infoblock h2{
    color: red;
    margin-top: 0;
}
.infoblock{
	margin-bottom: 1em;
    
}

.pimgs{
    display: flex;
    flex-wrap: nowrap;
}

.infoblock img{
    margin: 4px;
}


ul.infoblock > li{
    list-style: none;
}

ul.infoblock{
    padding-inline-start: 0px;
}

.infoblock div:last-child {
    margin-bottom: 0px;
}