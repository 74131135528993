ul.partners{
    display: flex;
    padding-inline-start: 0px;
    flex-wrap: wrap;
    justify-content: center;
    margin-block-end: 1em;
}

ul.partners > li{
    list-style: none;
    flex: 1 1 300px;
    padding-left: 20px;
    padding-bottom: 3em;
}
.partners img{
    margin: 0.2em;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 0.2em;
    box-shadow: 1px 1px 8px hsla(0, 0%, 89%, 1);
}