
.overlay{
  position: fixed;
  background-color: black;
  width: 100vw;
  height: 100vh;
  animation: 0.5s cubic-bezier(0.29, 0, 0.29, 0.95) overl;
  opacity: .7;
  z-index: 6;
  top: 0;
  left: 0;
}

@keyframes overl {
  from {
  opacity: 0;
  }
  to {
  opacity: .7;
  }
}

.m-top-menu{
  border-radius: 0px;
  width: 2em;
  margin-left: auto;
  position: absolute;
  right: 1em;
  padding-top: 1em;
}


.Mob-header {
    /*display: flex;
    flex-direction: column;
    align-content: flex-end;*/
    text-align: center;
    padding-inline-start: 0px;    
    font-weight: 600;
    margin: 0;

    background-color: white;

    position: absolute;
    z-index: 10;
    top: 3rem;
    width: 100%; /* 15em; */
    padding: 1em;
    animation: 0.5s cubic-bezier(0.29, 0, 0.29, 0.95) mobmenu;
    box-shadow: 0px 4px 5px 0px rgba(9, 9, 9, .2);
}

@keyframes mobmenu {
  from {

  opacity: 0;

  }

  to {

  opacity: 1;
  }
}


.Mob-header li {
    list-style: none;
    border-bottom: 1px solid #eaeaea;
    line-height: 2.3em;
}

.Mob-header li:last-child {
    list-style: none;
    border-bottom: 0px;
    line-height: 2.3em;
}

.Mob-header > li > a {
  display: block;
  line-height: 2rem;
}

