.mobMenu{
    list-style: none;
    position: fixed;
    right: 2px;
    top: 6.1em;
    background-color: #e9e9ec;
    width: 80vw;
    max-height: 80vh;
    min-height: 23vh;   
    padding-inline-start: 0px;
    box-shadow: -1px 3px 14px #9f9f9f;
    border-radius: .5em;
    overflow-y: auto;
    border: 3px solid #fff;
    padding: 0.5em .3em;
    z-index: 11;
}


.TabMenu{
    list-style: none;
    position: absolute;
    left: 47px;
    top: -13px;
    background-color: #e9e9ec;
    max-height: 50vh;
    padding-inline-start: 0px;
    overflow-y: auto;
    border: 3px solid #fff;
    padding: 0.5em 0.3em;
    z-index: 11;
}


.mobMenu, .TabMenu > li{
    padding: 1em 0em 1em .5em;
}

.itmMenu{
    /*background-image: url('/svg/itm-menu.svg'); 
    background-size: contain;*/
    position: fixed;
    right: 0vw;
    top: 5em;
    z-index: 2;    
}
ul.TabMenu a > span > span {
    padding-left: .4em;
}
ul.TabMenu a > span > b {
    padding-right: .3em;
}

.itmMenuTab{
    position: relative;
    left: -49px;
    top: 3px;
    z-index: 2;
    cursor: pointer;
    height: 0;
}

.overlay_tab {
    position: fixed;
    background-color: black;
    width: 100vw;
    height: 100vh;
    animation: 0.5s cubic-bezier(0.29, 0, 0.29, 0.95) overl;
    opacity: .7;
    z-index: 10;
    top: 0;
    left: 0;
}

.open{
    z-index: 11;
}

ul.edprt{
    background-color:#02a1b0;
    padding-left: 3px;
}

.edprt li{
    background-color: #fff;
    padding-left: 1em;
    padding-top: 1em;
    min-height: 240px;
    clear: both;
}
.edprt li > a{
    padding: 0px;
    font-size: 12pt;
    font-weight: 600;
}
.edprt li > div{
    margin-top: 1em;
}

.edprt img{
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 1em;
    max-width: 150px;
    min-width: 149px;
}

.top{
    position: fixed;
    right: 20px;
    bottom: 10em;
    animation: .3s cubic-bezier(0.29, 0, 0.29, 0.95) top; 
    cursor: pointer;
}
@keyframes top {
    from {
    bottom: 15em;
    opacity: 0;
    }
  
    to {
    bottom: 10em;
    opacity: 1;
    }
  }

/* ==================================================== > 650 =========== */
@media (min-width: 650px){
    .top{
        position: fixed;
        right: 10em;
        bottom: 5em;
        animation: .3s cubic-bezier(0.29, 0, 0.29, 0.95) top; 
    }

    @keyframes top {
        from {
        bottom: 15em;
        opacity: 0;
        }
      
        to {
        bottom: 5em;
        opacity: 1;
        }
      }

}


